import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Modal from 'react-modal';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const root = ReactDOM.createRoot(document.getElementById('root'));

const initialOptions = {
  clientId: "AXmakHsGmaB-Xe030hLqFtjygf_YkYIo3RQDmBVOPCSx0B5_RI1e50JkPBmi0gp0_MIJl95fOwowScSt",
  currency: "GBP",
  intent: "capture",
};

Modal.setAppElement('#root'); // replace '#root' with the id of your root element


root.render(
  <React.StrictMode>
     <PayPalScriptProvider options={initialOptions}>
    <App />
    </PayPalScriptProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
