import logo from './logo.svg';
import './App.css';
import WorkshopList from './WorkshopList';

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src='/HeaderLogo.webp' className="App-header img" />           
      </header>
      <div>
      <h1>In Person Workshops</h1>
      <WorkshopList/>
    </div>
    </div>
  );
}

export default App;
