import React, { useState, useEffect } from 'react';
import WorkshopItem from './WorkshopItem';
import WorkshopDetails from './WorkshopDetails';

const WorkshopList = () => {
  
  const [workshops, setWorkshops] = useState([]);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);


  useEffect(() => {
    fetch('/api/workshops')
      .then(response => response.json())
      .then(data => setWorkshops(data));
  }, []);

  return (
    <div className="WorkshopList">
      {selectedWorkshop ? (
        <WorkshopDetails workshop={selectedWorkshop} onBack={() => setSelectedWorkshop(null)} />
      ) : (
        workshops.map(workshop => (
          <WorkshopItem
            key={workshop.id}
            workshop={workshop}
            onSelect={setSelectedWorkshop}            
          />
        ))
      )}
    </div>
  );
};

export default WorkshopList;