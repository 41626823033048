
import React from 'react';

const WorkshopItem = ({ workshop, onSelect }) => (
  <div className="WorkshopItem" onClick={() => onSelect(workshop)}>
    <h2>{workshop.title}</h2>
    <p>{workshop.date}</p>
    <p>Open slots: {workshop.openSlots}</p>
  </div>
);

export default WorkshopItem;