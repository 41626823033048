import React from 'react';
import { PayPalButtons } from "@paypal/react-paypal-js";
import Modal from 'react-modal';

const WorkshopDetails = ({ workshop, onBack }) => {
  const [paymentSuccessful, setPaymentSuccessful] = React.useState(false);

  
  const createOrder = (data, actions) => {

    console.log('Creating order');

    return fetch('/api/create-order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: workshop.id, 
        price: workshop.price, 
        reference: workshop.title + ' - ' + workshop.date,
      }),      
    })
    .then(response => response.json())
    .then(data => {
      console.log('Order created:', data);
      return data.id; // Ensure your endpoint returns the order ID in this field
    });
  };

  const onApprove = (data, actions) => {
    return fetch(`/api/capture-order?orderId=${data.orderID}`, {
      method: 'POST',
    })
    .then(response => response.json())
    .then(details => {
      // Assuming the capture endpoint response contains the details of the captured order



      console.log('Order captured successfully', details);
      setPaymentSuccessful(true);
    })
    .catch(error => {
      console.error('Error during order capture:', error);
      // Handle error case here
    });
  };

  return (
    <div>
      <button onClick={onBack}>Back</button>
      <h2>{workshop.title}</h2>
      <p>{workshop.description}</p>
      <p>{workshop.date}</p>           
      <p>Open slots: {workshop.openSlots}</p>
        {!paymentSuccessful &&  <PayPalButtons 
            createOrder={createOrder}
            onApprove={onApprove}
        />
        }
        <Modal
        isOpen={paymentSuccessful}
        onRequestClose={() => setPaymentSuccessful(false)}
        contentLabel="Payment Successful"
      >
        <h2>Payment Successful!</h2>
        <p>Please check your email for further details!</p>
        <button onClick={() => setPaymentSuccessful(false)}>Close</button>
      </Modal>
    </div>
  );
};

export default WorkshopDetails;